<template>
  <div class="article-message-page">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/admin/index' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/index' }">应用中心</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/index' }">会议壳</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/meeting-list' }">会议列表</el-breadcrumb-item>
      <el-breadcrumb-item>{{this.$route.query.itemName}}</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/admin/apps/shell/content/index/' + this.$route.params.mId }">会议内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>文章留言管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="page-content" v-if="messageList.length > 0">
        <el-table :data="messageList" border style="width: 100%" max-height="500">
          <el-table-column prop="title" label="文章标题" width="120"></el-table-column>
          <el-table-column prop="nick" label="昵称" width="120"></el-table-column>
          <el-table-column prop="title" label="头像" width="70">
            <template slot-scope="scope">
              <el-image :src="scope.row.avatar" style="width: 50px;height: 50px;"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="message" label="留言内容"></el-table-column>
          <el-table-column prop="reply" label="回复内容">
            <template slot-scope="scope">
              <el-tag type="warning" effect="dark" v-if="!scope.row.reply">未回复</el-tag>
              <el-tag type="success" effect="dark" v-else>
                {{scope.row.reply}}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="是否展示">
            <template slot-scope="scope">
              <el-switch style="display: block" v-model="scope.row.status" :active-value="2" :inactive-value="1" active-color="#13ce66" inactive-color="#ff4949" active-text="显示" inactive-text="隐藏" @change="statusChange(scope.row.m_id, $event)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="replyItem(scope.row.m_id)">回复</el-button>
              <el-button type="danger" size="mini" @click="delItem(scope.row.m_id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="no-list" v-else>
        <el-empty :image-size="200"></el-empty>
      </div>
    </div>
    <!-- 回复内容弹窗 -->
    <el-dialog title="回复留言" :visible.sync="replyShow" width="60%">
      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4}" placeholder="请输入回复内容" v-model="replyForm.reply">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="replyShow = false">取 消</el-button>
        <el-button type="primary" @click="submitReply">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Message',
  data () {
    return {
      messageList: [],
      replyShow: false,
      replyForm: {
        m_id: '',
        reply: ''
      }
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/shell-article-message-list', { params: { mId: this.$route.params.mId } })
      if (res.status === 200) {
        console.log(res.data)
        this.messageList = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    // 更改每页显示数量
    handleSizeChange (val) {
      this.query.pageSize = val
      this.getPageData()
    },
    // 更改页码
    handleCurrentChange (val) {
      this.query.currentPage = val
      this.getPageData()
    },
    // 回复消息
    replyItem (mid) {
      this.replyForm.m_id = mid
      this.replyForm.reply = ''
      this.replyShow = true
    },
    // 提交回复
    async submitReply () {
      const { data: res } = await this.$http.post('/shell-article-message-reply', this.replyForm)
      if (res.status === 200) {
        console.log(res.data)
        await this.getPageData()
        this.replyShow = false
      } else {
        this.$message.error(res.msg)
      }
    },
    // 修改留言状态
    async statusChange (mid, newVal) {
      const { data: res } = await this.$http.put('/shell-article-message-reply', { m_id: mid, status: newVal })
      if (res.status === 200) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
      await this.getPageData()
    },
    // 删除消息
    async delItem (mid) {
      const { data: res } = await this.$http.delete('/shell-article-message-reply', { params: { m_id: mid } })
      if (res.status === 200) {
        this.$message.success(res.msg)
      } else {
        this.$message.error(res.msg)
      }
      await this.getPageData()
    }
  }
}
</script>

<style scoped>

</style>
